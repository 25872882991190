import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
    EmailShareButton,
    EmailIcon,
    RedditShareButton,
    RedditIcon
} from 'react-share'

const ShareButtons = ({ title, urlPath, twitterHandle, tags }) => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `
    )

    const baseUrl = site.siteMetadata?.siteUrl;
    const url = `${baseUrl}${urlPath || '/'}`;

    const styleClasses = "mx-0.5"

    return (
        <div>
            <FacebookShareButton url={url} className={`${styleClasses}`}>
                <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags} className={`${styleClasses}`}>
                <TwitterIcon size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={url} className={`${styleClasses}`}>
                <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>

            <WhatsappShareButton url={url} title={title} className={`${styleClasses}`}>
                <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>

            <TelegramShareButton url={url} title={title} className={`${styleClasses}`}>
                <TelegramIcon size={40} round={true} />
            </TelegramShareButton>

            <EmailShareButton url={url} subject={title} body={"Check out this article!"} className={`${styleClasses}`}>
                <EmailIcon size={40} round={true} />
            </EmailShareButton>

            <RedditShareButton url={url} title={title} className={`${styleClasses}`}>
                <RedditIcon size={40} round={true} />
            </RedditShareButton>
        </div>
    )
}

export default ShareButtons