import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import moment from 'moment-strftime';

import AssetImage from "../components/AssetImage"
import Bio from "../components/bio"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import PostCard from "../components/widget/PostCard"
import ShareButtons from "../components/widget/ShareButtons"
import TagsList from "../components/widget/TagsList"
import { NewsletterBanner } from "../components/NewsletterBanner";

const shortcodes = {
  Link,
}

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Manu Sobles `;
  const { previous, next } = data;
  const tagsGroup = { group: post.frontmatter.tags.map(tag => { return { fieldValue: tag, totalCount: 0 } }) }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        imagePath={post.frontmatter.imgPath}
        imageAlt={post.frontmatter.imgAlt}
        pathName={post.frontmatter.slug}
        article={true}
      />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="max-w-screen-sm mx-auto mt-12 mb-10">
          <div className="flex felx-wrap flex-col sm:flex-row uppercase text-sm text-meta leading-6 tracking-widest mb-3.5">
            <time className="published" dateTime={moment(post.frontmatter.date).strftime('%Y-%m-%d %H:%M')}>
              {moment(post.frontmatter.date).strftime('%A, %e %B %Y')}
            </time>

            <div className="hidden sm:inline-block">
              {"\u00A0\u00A0\u00A0•\u00A0\u00A0\u00A0"}
            </div>

            <div>
              {post.timeToRead} min read
            </div>
          </div>

          <h1 itemProp="headline">{post.frontmatter.title}</h1>

          <div className="text-2xl">{post.excerpt}</div>

          <TagsList tagsGroup={tagsGroup} />
        </header>

        {post.frontmatter.imgPath && (
          <div className="block mb-10">
            <AssetImage
              imgClassName="rounded"
              imgPath={post.frontmatter.imgPath}
              imgAlt={post.frontmatter.imgAlt}
              imgCaption={post.frontmatter.imgCaption}
            />
          </div>
        )}

        <section itemProp="articleBody" className="max-w-screen-sm mx-auto mb-12">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>

          <div>
            <p className="mb-2">If you found this article interesting, share it!</p>
            <ShareButtons
              title={post.frontmatter.title}
              urlPath={post.frontmatter.slug}
              twitterHandle={data.site.siteMetadata.social.twitter}
              tags={post.frontmatter.tags}
            />
          </div>
        </section>

        <hr className="max-w-screen-sm mx-auto" />

        <footer>
          <Bio />
        </footer>
      </article>

      <NewsletterBanner />

      <div className="max-w-screen-md block mx-auto mt-6 sm:flex sm:flex-wrap justify-start">
        {previous && (
          <div className="w-full md:w-1/2">
            <div className="my-2 mx-2">
              <Link
                to={previous.fields.slug}
                itemProp="url"
              >
                ← Previous
              </Link>
            </div>
            <PostCard
              post={previous}
              key={previous.fields.slug}
            />
          </div>
        )}
        {next && (
          <div className="w-full md:w-1/2">
            <div className="my-2 mx-2 text-right">
              <Link
                to={next.fields.slug}
                itemProp="url"
              >
                Next →
            </Link>
            </div>
            <PostCard
              post={next}
              key={next.fields.slug}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt
      timeToRead
      body
      frontmatter {
        slug
        tags
        title
        date
        excerpt
        imgPath: img_path
        imgAlt: img_alt
        imgCaption: img_caption
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      id
      excerpt
      timeToRead
      fields {
        slug
      }
      frontmatter {
        tags
        title
        date
        excerpt
        imgPath: img_path
        imgAlt: img_alt
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      id
      excerpt
      timeToRead
      fields {
        slug
      }
      frontmatter {
        tags
        title
        date
        excerpt
        imgPath: img_path
        imgAlt: img_alt
      }
    }
  }
`
